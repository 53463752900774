/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import Lightbox from "react-image-lightbox"
import PageWrapper from "../../layouts/PageWrapper"
import OrganisationImg from "../../img/orga2022.png"
import HomeLogo from "../../components/HomeLogo"

import "react-image-lightbox/style.css"

export default class OurOrganisationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { isOpen } = this.state

    return (
      <PageWrapper>
        <Helmet>
          <title>Our Organisation - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Our Organisation
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hero organisation section">
          <div className="hero-body">
            <div className="container">
              <img
                id="org-image"
                className="pointer"
                onClick={() => this.setState({ isOpen: true })}
                style={{ width: "90%" }}
                src={OrganisationImg}
              />
              {isOpen && (
                <Lightbox
                  wrapperClassName="organisation-lightbox"
                  mainSrc={OrganisationImg}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                />
              )}
            </div>
          </div>
        </section>
      </PageWrapper>
    )
  }
}
